import React, { useEffect, useState } from 'react'
import type { PageProps } from 'gatsby'
import { NpmPage404 } from '@plurix/ecom-pages'
import { HAS_WINDOW } from '@plurix/ecom-pages/src/constants'

function Page({ location }: PageProps) {
  const [fromUrl, setFromUrl] = useState<undefined | string>()

  useEffect(() => {
    if (!location.href || !HAS_WINDOW) {
      return
    }

    setFromUrl(
      location.href
        .replace('404?from=%252F', '')
        .replaceAll('%252F', '/')
        .replace('}', '')
    )
  }, [location.href])

  return <NpmPage404 fromUrl={fromUrl} />
}

export default Page
